.recommend-products-tab {
  margin-left: 40px;
}

.recommend-product-details {
  display: flex;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: 300px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.recommend-product-details:hover {
  cursor: pointer;
}

.recommend-product-image-container {
  margin-right: 50px;
}

.recommend-product-image {
  width: 250px;
  height: 250px;
  transition: transform 0.3s;
  opacity: 1;
}

.recommend-discount-container {
  display: block;
  text-align: left;
  padding-top: 10px;
  width: 120px !important;
  height: 50px;

  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  background-color: #cfe320;
}

.recommend-product-title-container {
  display: block;
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  white-space: wrap;

  overflow-y: scroll;
  max-height: 140px;
  position: relative;
  left: -10px;
}

.recommend-product-price-container {
  display: flex;
  flex-direction: row;
  position: relative;
  left: -10px;
  padding-top: 20px;

  .column {
    text-align: left;
    display: flex;
    flex: 1;

    height: 50px;
    flex: 2 1 auto;
  }

  .asking-price {
    font-size: 18px;
    font-weight: 400;
    position: relative;
    bottom: 3px;
  }

  .original-price {
    padding-top: 0px;
    width: 40%;
    font-size: 15px;
    font-weight: 300;
    color: #969696;
    text-decoration: line-through;
    margin-left: 10px;
  }

  .recommend-product-rating-icon-text {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .recommend-product-rating-icon {
    width: 45px;
    height: 40px;
  }

  .recommend-product-rating-text {
    height: 40px;
    margin-left: -5px;
    font-size: 16px;
  }

  .recommend-product-rating-number-of-comments {
    margin-left: 2px;
    color: #969696;
    font-size: 16px;
    font-weight: 300;
  }
}
