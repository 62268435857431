.signup-container {
  min-width: 600px;
}

.input-error {
  border-color: red !important;
}

.error-message {
  font-size: 13px;
  color: red;
}

.create-icon {
  position: relative;
  left: 70px;
}

.divider {
  position: relative;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 15px 0;
  border-bottom: 1px solid rgb(229, 230, 235);
  clear: both;
}

.info-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}

.password-format {
  font-size: 13px;
  font-weight: 300;
  margin: 15px 10px;
}

.back-step-button > span {
  left: 35%;
}

.continue-step-button > span {
  left: 40%;
}

.back-step-button {
  margin-right: 20px;
  background-color: white !important;
  border: 1px solid rgb(17, 22, 17) !important;

  &:not([disabled]):hover {
    color: rgb(134, 144, 156) !important;
  }
}

.mobile-signup-modal-container {
  padding-left: 0px;
  width: 100%;
  height: 100vh;
}

.mobile-input-container {
  height: 5vh;
}

div.mobile-signup-container input {
  font-size: 13px;
}

.mobile-not-have-account {
  font-size: 13px;
}

.mobile-create-account {
  font-size: 13px;
  position: relative;
  float: right;
  margin-right: 10px;
}

.mobile-form-btn {
  font-size: 13px;
}

.mobile-password-format {
  font-size: 11px;
  font-weight: 300;
  margin: 15px 10px;
}

.mobile-form-continue {
  margin-left: 0px;
}

.mobile-info-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.mobile-divider {
  position: relative;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 10px 0;
  border-bottom: 1px solid rgb(229, 230, 235);
  clear: both;
}

.mobile-continue-step-button > span {
  left: 30%;
}

.mobile-error-message {
  font-size: 13px;
  color: red;
}
