.secondary-navbar-search-bar {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 16px;
  padding-left: 50px;
  margin: 0 70px;
  width: 100%;
}

.secondary-navbar-search-icon {
  position: absolute;
  left: 85px;
  font-size: 20px;
  color: #333;
}
