.mobile-private-policy-page-container {
  display: flex;
  flex-direction: column;

  .mobile-privacy-policy-container {
    margin: 20px;

    .privacy-content {
      font-size: 17px;
    }
    h1 {
      text-align: center;
    }

    ul {
      list-style-type: none;
      padding-left: unset;
    }

    li {
      margin-bottom: 20px;
      text-align: left;
      font-size: 15px;
    }
  }
}
