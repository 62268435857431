.secondary-navbar-container {
  background-color: #f0f0f0;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1;
}

.secondary-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;

  margin: 0 auto;
  max-width: 1344px;
}

.secondary-navbar-left {
  display: flex;
  align-items: center;
  margin-left: 30px;
  max-width: 300px;
}

.icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.shop-category {
  font-size: 16px;
  font-weight: 300;
  margin-left: 15px;
}

.secondary-navbar-middle {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
}

.secondary-navbar-right {
  display: flex;
  align-items: right;
  margin-right: 0px;

  width: 200px;
}

.navbar-category-bar-container {
  position: fixed;
  top: 120px;
  width: 100%;
  z-index: 100;

  height: 250px;

  .navbar-category-dropdown-container {
    display: flex;
    position: relative;
    max-width: 1344px;

    margin: 0 auto;

    height: 350px;
  }

  .navbar-category-dropdown-menu {
    display: flex;
    flex-direction: column;

    background-color: #ffffff;
    width: 207px;
    position: relative;
    left: 20px;
    text-align: left;

    padding-top: 20px;
    border: 1px solid #f5f8e4;
  }

  .navbar-main-category-dropdown-title {
    flex: 1;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0px 0 20px;
  }

  .navbar-category-dropdown-item {
    flex: 1;
    font-weight: 400;
    padding: 15px 0px 0px 20px;
  }

  .navbar-category-dropdown-item-icon {
    margin: 0 0 3px 10px;
  }

  .navbar-category-dropdown-item :hover,
  .navbar-category-dropdown-item.selected {
    background-color: #f5f8e4;
  }

  .navbar-category-dropdown-item.selected {
    border-left: 8px solid #cfe320;
  }

  .navbar-category-subcategory-menu {
    width: 237px;
    display: flex;
    flex-direction: column;
    height: auto;

    margin: 0 auto;
    position: relative;
    right: 327px;

    bottom: 350px;
    text-align: left;

    background-color: #f5f8e4;
    padding-top: 20px;
  }

  .navbar-sub-category-dropdown-title {
    height: 60px;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0px 0 20px;
  }

  .navbar-category-subcategory-item {
    height: 60px;
    font-weight: 400;
    padding: 10px 0px 0px 20px;
    text-decoration: none;
    color: black;
    white-space: nowrap;
  }

  .navbar-category-subcategory-item:hover {
    text-decoration: underline;
  }
}
