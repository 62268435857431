.mobile-footer-container {
  background-color: #e9e8e8;

  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .footer-col {
    flex: unset;
    height: 120px;
    display: flex;
    justify-content: center;
    font-size: 14px;

    .footer-text {
      flex: 1;
      max-height: 40px;
      font-size: 14px;
      input {
        top: unset;
      }

      span {
        font-weight: 300;
      }
    }

    .footer-title,
    .footer-link {
      display: flex;
      align-items: center;
    }

    .footer-title {
      font-size: 18px;
      font-weight: 500;
    }
    .footer-link {
      font-weight: 300;
    }
  }
}
