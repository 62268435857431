.no-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 150px 100px 20px 100px;
  padding: 30px 0px 30px 0px;
  background-color: #fff;
  border-radius: 13px;
  min-height: 55vh;
}

.no-product-image {
  width: 500px;
  height: 300px;
}

.no-product-message {
  width: 50%;
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}

.find-missing-item-on-amazon {
  background-color: #befc95;
  width: 300px;
  border-radius: 10px;
  height: 50px;
  font-weight: 400;
}
