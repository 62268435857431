.mobile-products-group-body {
  padding: 0 10px;
  .product-group-body-list {
    display: grid;
    grid-template-columns: repeat(2, 46vw);
    grid-template-rows: repeat(2, 30vh);
    margin: 20px 10px;
    gap: 5px;
    box-sizing: border-box;

    .product-group-body-grid-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-decoration: none;
      color: black;
      margin-right: 5px;
    }

    .product-group-body-grid-item-image-container {
      background-color: #f5f5f5;
      width: 100%;
      height: 100%;

      .AsyncImage-root {
        position: relative;
        top: 10px;
        left: 10px;
      }
    }

    .product-group-item-product-name {
      width: 100%;
      height: 25px;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      margin-right: 5px;
      display: flex;
      align-items: center;
      position: relative;
      bottom: 5px;
    }

    .product-group-item-price-and-rating {
      display: flex;
      width: 100%;
      height: 25px;
      flex-direction: row;
      column-gap: 10px;
      font-size: 12px;
      align-items: center;

      .product-group-item-list-price {
        flex-grow: 1;
        text-decoration: line-through;
        color: #969696;
        font-weight: 300;
      }

      .product-group-item-rating {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;

        .product-group-item-rating-icon {
          position: relative;
          bottom: 1px;
        }

        .product-group-item-rating-number {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .product-group-item-rating-count {
          color: #969696;
          font-size: 12px;
        }
      }
    }

    .product-group-item-status-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      width: 100%;
      position: relative;
      bottom: 77%;
      font-size: 12px;

      .product-group-item-discount {
        display: flex;
        justify-content: center;
        width: 70px;
        background-color: #cfe320;
      }

      .product-group-item-limited {
        background-color: #0ca788;
        width: 80px;
        display: flex;
        justify-content: center;
      }

      .product-group-item-hot {
        background-color: #ff7f40;
        width: 60px;
        display: flex;
        justify-content: center;
      }
    }

    img {
      filter: contrast(90%);
    }
  }

  .product-group-pagination {
    display: flex;
    justify-content: center;
  }
}
