.redesign-product-detail-page {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: auto;
  margin-top: 40px;
  max-width: 1344px;

  position: relative;
  top: 100px;

  .column {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .row {
    display: flex;
    height: 200px;
  }

  .product-description-image-gallery {
    height: 700px;
  }

  .product-favorite-button-container {
    height: 44px;
    width: 44px;

    position: relative;
    bottom: 650px;
    left: 650px;
    background-color: #d9d9d9;
    border-radius: 44px;
  }

  .product-favorite-button-container svg {
    position: relative;
    top: 4px;
    color: white;
  }

  .product-favorite-button-container svg:hover {
    cursor: pointer;
  }

  .product-description-tabs {
    flex: 2;
    position: relative;
    bottom: 40px;
  }

  .product-description-chat {
    flex: 1;
  }

  .product-description-right-column {
    margin-left: 40px;
    margin-top: 50px;
  }

  .product-description-top-right {
    height: 650px;
  }

  .product-description-price {
    height: 100px;
  }

  .product-description-rating {
    height: 150px;
  }

  .product-description-buttons {
    height: auto;
  }

  .product-description-recommend-products {
    flex: 3;
  }

  .discount-status-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 50px;
  }

  .discount-container {
    display: block;
    text-align: center;
    margin-left: 50px;
    padding-top: 10px;
    width: 120px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    background-color: #cfe320;
  }

  .status-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 50px;
  }

  .status-container {
    display: block;
    margin-left: 50px;
    width: 180px;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    padding-top: 10px;
    border-radius: 10px;
    background-color: #eaeaea;
  }

  .product-title-container {
    display: block;
    margin-left: 40px;
    margin-top: 10px;
    font-size: 22px;
    font-weight: 300;
    text-align: left;
    white-space: wrap;

    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
    height: auto;
    max-height: 200px;
  }

  .product-price-container {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    position: relative;
    top: 20px;

    .column {
      text-align: left;
      display: flex;
      flex: 1;

      height: 50px;
      flex: 2 1 auto;
    }

    .asking-price {
      font-size: 40px;
      font-weight: 500;
    }

    .original-price {
      padding-top: 10px;
      width: 70%;
      font-size: 25px;
      font-weight: 500;
      color: #969696;
      text-decoration: line-through;
    }
  }

  .product-rating-container {
    display: flex;
    align-items: left;
    font-size: 16px;
    margin-left: 40px;
    flex-direction: column;
    height: 70%;

    .product-rating-col {
      max-height: 50px;
    }

    .product-rating-icon {
      display: block;
      text-align: left;
    }

    .product-rating-icon svg {
      width: 30px;
    }

    .product-rating-text {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 300;

      position: relative;
      top: 2px;
    }
  }

  .product-buttons-container {
    display: flex;
    align-items: center;
    margin-left: 40px;

    .product-buttons-code {
      width: 80%;
      height: 60px;
      display: flex;
      justify-content: center;

      background-color: #cfe320;
      border-radius: 10px;

      span {
        font-size: 20px;
        font-weight: 600;
        position: relative;
        top: 12px;
      }
    }

    .product-button:hover {
      cursor: pointer;
    }

    .product-button-subcontent {
      width: 80%;
      height: 80px;
      background-color: #eaeaea;
      border-radius: 10px;
      position: relative;
      top: -40px;
      z-index: -1;

      display: flex;
      justify-content: center;

      span {
        font-size: 16px;
        font-weight: 400;
        color: #747171;
        position: relative;
        top: 50px;
      }
    }

    .product-buttons-link {
      width: 280px;
      height: 60px;
      background-color: #f0c401;
      border-radius: 10px;

      span {
        font-size: 20px;
        font-weight: 600;
        position: relative;
        top: 12px;
        display: flex;
        justify-content: center;
      }
    }

    .product-button-link-subcontent {
      width: 280px;
      height: 80px;
      background-color: #eaeaea;
      border-radius: 10px;
      position: relative;

      top: -40px;
      z-index: -1;

      span {
        font-size: 16px;
        font-weight: 400;
        color: #747171;
        position: relative;
        top: 50px;
      }
    }
  }
}
