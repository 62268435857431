.trending-product-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.trending-product-row {
  display: flex;
}

.trending-product-row-title {
  flex: 1;
}

.trending-product-row-products-title {
  font-size: 24px;
  font-weight: 600;
  width: 600px;
  text-align: left;

  align-items: flex-start;
  justify-content: flex-start;
}

.trending-product-container .product-box-discount {
  width: 90px;
  height: 25px;
  color: #000000;
  background-color: #cfe320;

  font-size: 16px;
  font-weight: 700;
  position: relative;
  bottom: 210px;
  right: 70px;
}

.trending-product-container .product-box-hot {
  background-color: #ff7f40;
  color: white;
  width: 70px;
  height: 25px;
  font-size: 16px;
  font-weight: 700;

  position: relative;
  bottom: 235px;
  left: 80px;
}

.trending-product-container .product-box-limited {
  background-color: #0ca788;
  color: white;
  width: 90px;
  height: 25px;
  font-size: 16px;
  font-weight: 700;

  position: relative;
  bottom: 235px;
  left: 70px;
}

.trending-product-container .product-box-label-hidden {
  visibility: hidden;
  width: 90px;
  height: 25px;
}

.trending-product-row-products-pagination {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  align-items: right;
  width: 100%;
}

.trending-product-row-product-grid {
  flex: 5;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);

  gap: 10px;

  padding: 0 10px;
  box-sizing: border-box;

  margin-top: 20px;
}

.trending-product-row-product-grid .product-box {
  flex: 1 1 calc(20% - 20px); /* Adjust based on your needs */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 300px;
  box-sizing: border-box;
}

.trending-product-row-product-grid .product-box:hover {
  cursor: pointer;
}

.trending-product-row-product-grid .product-box-image {
  width: 230px;
  height: 300px;
  background-color: #f5f5f5;
}

.trending-product-row-product-grid img {
  filter: contrast(90%);
}

.trending-product-row-product-grid .product-box-product-name {
  height: 30px;
  width: 200px;

  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: -30px;
  margin-left: 10px;
  margin-right: auto;
}

.product-box-product-price-and-rating {
  height: 30px;
  display: flex;
  flex-direction: row;

  font-size: 13px;
  width: 230px;

  position: relative;
  top: -30px;
  margin-right: auto;
}

.product-box-product-price-price {
  width: 60px;
  float: left;
}

.trending-product-row-product-grid .product-box-product-price-list-price {
  font-weight: 300;
  width: 50px;
  margin-right: 5px;
  text-decoration: line-through;
  color: #969696;
}

.trending-product-row-product-grid .product-box-product-price-rating {
  width: 120px;
}

.product-box-product-price-rating span {
  position: relative;
  margin-left: 10px;
}

.product-box-product-price-rating .product-box-product-rating-icon {
  position: relative;
  top: -2px;
}

.product-box-product-price-rating .product-rating-count {
  color: #969696;
  font-size: 13px;

  position: relative;
  top: 0px;
}
