.privacy-policy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  width: 1344px;
  margin: auto;

  position: relative;
  top: 150px;
  min-height: 55vh;

  ul {
    list-style-type: none;
  }

  li {
    margin-bottom: 20px;
    text-align: left;
  }
}

.privacy-content {
  font-size: 17px;
  margin-bottom: 20px;
  text-align: left;
}

.privacy-title {
  margin-bottom: 10px;
}

.privacy-item {
  margin-bottom: 10px;
}

.nested-list {
  margin-left: 20px;
}
