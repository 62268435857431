.directory-navbar {
  display: flex;
  justify-content: space-between;
  align-items: left;

  margin: 0 auto;
  max-width: 1344px;
  padding-top: 40px;

  position: relative;
  top: 120px;
}

.directory-navbar-left {
  display: flex;
  align-items: left;

  margin-left: 30px;
}

.category-link {
  color: black;
  text-decoration: underline;
}
