.chatbox-container {
  height: 500px;
  transform: rotateY(0);
  width: 600px;

  flex: 1;

  .cs-message-input__content-editor {
    background-color: white;
    border-radius: 5px;

    padding-left: 20px;
    text-align: left;
  }
}

.cs-main-container {
  border-radius: 20px;
}

.cs-message--incoming .cs-message__content,
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-container {
  background-color: #cfe320;
  border-radius: 5px;
}

.cs-message--outgoing .cs-message__content {
  background-color: #e8e8e8;
  border-radius: 5px;
}
