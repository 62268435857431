.welcome-body {
  width: 500px;
  margin: 50px 0;
  font-size: 18px;
  font-weight: 300;
  text-wrap: wrap;
}

.welcome-modal-button {
  font-size: 18px !important;
  font-weight: 400 !important;
  span {
    left: 38%;
  }
}
