.redesign-navbar-container {
  background-color: #cfe320;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px 20px !important;

  max-width: 1344px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
}

.navigation-links {
  margin-left: 20px;
}

.navigation-links a {
  color: black;
  font-size: 12;
  font-weight: 300;
  text-decoration: none;
  margin: 5px 20px 0px 20px;
}

.navigation-links a:hover {
  text-decoration: underline;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navbar-right .icon {
  font-size: 30px;
  margin: 0 12px;
  cursor: pointer;
  // background-color: #DDE9A9;

  &--circle {
    display: inline-block;
    padding: 30px;
    background-color: #dde9a9;
    border-radius: 4em;
    color: white;
  }
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #dde9a9;
  border-radius: 50%;
}

.icon-container:nth-child(2) {
  margin-right: 10px;
}

.navbar-right .icon:hover {
  font-size: 50px;
}

.navbar-right .icon-container:hover {
  background-color: white;
}
