.create-account-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;
}
.not-have-account {
  font-size: 16px;
}

.create-account {
  font-size: 16px;
  top: 10px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.login-error-message {
  font-size: 16px;
  color: red;
  position: relative;
  top: -10px;
  margin-left: 5px;
}

button > span {
  left: 40%;
}

.mobile-login-modal-container {
  padding-left: 0px;
  width: 90%;
}

div.mobile-login-modal-container input {
  font-size: 13px;
}

.mobile-login-form-field {
  width: 80%;
  font-size: 13px;
}

.mobile-not-have-account {
  font-size: 13px;
  position: relative;
  top: 8px;
}

.mobile-create-account {
  font-size: 13px;
  position: relative;
  float: right;
  top: 8px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.mobile-form-btn {
  font-size: 16px;
}
