.mobile-product-group-title-directory {
  margin: 10px 20px 0 20px;

  .mobile-product-group-category-link {
    text-decoration: none;
    color: black;
  }

  .mobile-product-group-category-link:hover {
    cursor: pointer;
  }
}
