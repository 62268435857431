.mobile-chatbox-container {
  height: 35vh;
  transform: rotateY(0);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  .cs-message__html-content,
  .cs-typing-indicator__text {
    font-size: 12px;
  }

  .cs-message-input__content-editor {
    background-color: white;
    border-radius: 5px;
    margin: 0;
    text-align: left;
    font-size: 12px;
  }
}

.cs-main-container {
  border-radius: 20px;
}

.cs-message--incoming .cs-message__content,
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-container {
  background-color: #cfe320;
  border-radius: 5px;
  width: 100%;
}

.cs-message--outgoing .cs-message__content {
  background-color: #e8e8e8;
  border-radius: 5px;
}
