.product-group-container {
  display: flex;
  flex-direction: column;
  max-width: 1344px;

  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  position: relative;
  top: 130px;

  padding: 0 30px;
  height: 1700px;

  .product-group-row {
    display: flex;
    flex: 1;

    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }

  .product-group-title {
    max-height: 24px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .product-group-title-directory {
      width: 600px;
      font-weight: 600;
      text-align: left;

      .product-group-category-link {
        color: black;
      }
    }

    .product-group-title-sorting {
      display: flex;
      flex-direction: column;
      width: 300px;
      height: auto;

      background-color: white;
      border-radius: 7px;

      .product-group-sorting-btn {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-radius: 7px;
        padding: 0 20px;
      }

      .product-group-sorting-content {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        z-index: 1;
      }

      .product-group-sorting-content-hidden {
        display: none;
      }

      .product-group-sorting-options {
        position: relative;
        left: 40px;
        width: 220px;
        margin-bottom: 0px;
        margin-top: 10px;
        background-color: #fff;

        li:hover {
          background-color: #f2f2f2;
        }
      }

      .product-group-sorting-options li {
        height: 50px;
        border-radius: 7px;
        padding: 10px;
        // border: 1px solid red;
        display: flex;
        cursor: pointer;
      }
    }

    .product-group-title-sorting:hover {
      cursor: pointer;
    }
  }
}

.product-group-container {
  .product-group-body {
    flex: 4;
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 10px;

    .product-group-body-filter {
      flex: 1;
      position: relative;
      top: 10px;
    }
  }

  .product-group-row .product-group-pagination-bar {
    position: relative;
    left: 50%;
  }
}

.product-group-body-filter-apply {
  height: 45px;

  font-size: 18px;
  display: flex;
  flex-direction: row;
}

.group-filter {
  border-bottom: 1px solid #e8e8e8;
}

.product-group-body-filter-apply {
  .product-group-body-filter-clearall {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    text-align: left;
  }

  .product-group-body-filter-apply {
    align-items: flex-start;
    justify-content: flex-end;
    flex: 1;

    text-align: right;
  }

  .product-group-body-filter-btn:hover {
    cursor: pointer;
  }
}

.product-group-body-filter-price-range {
  height: 100px;
  margin-top: 30px;

  font-size: 18px;
}

.product-group-body-filter-price-range-title {
  display: flex;
}

.product-group-body-filter-price-range-title-left {
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  text-align: left;
}

.product-group-body-filter-price-range-title-right {
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
  text-align: right;

  font-weight: 600;
}

.product-group-body-filter-price-range-slider {
  height: 70px;

  .price-range-slider {
    width: 100%;
    height: 5px;
    background-color: #cfe320;
    margin-top: 35px;
  }

  .price-range-track {
    background-color: #cfe320;
  }

  .price-range-track-0,
  .price-range-track-2 {
    background-color: #d9d9d9;
    height: 5px;
  }

  .price-range-thumb {
    width: 23px;
    height: 23px;

    cursor: grab;
    background-color: #cfe320;
    border-radius: 50%;
    top: -8px;

    // border-color: white;
  }
}

.product-group-body-filter-discount {
  height: 200px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}

.product-group-body-filter-text {
  text-align: left;
  height: 40px;
}

.product-group-body-filter-option {
  height: 40px;
  text-align: left;
  display: flex;
  flex-direction: row;

  position: relative;
  left: 5px;
}

.product-group-body-filter-option input {
  transform: scale(1.5);
  position: relative;
  bottom: 5px;
}

.product-group-body-filter-option span {
  margin-left: 20px;
  position: relative;
  top: 3px;
}

.product-group-body-filter-option-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.product-group-body-filter-rating {
  height: 200px;
  display: flex;
  flex-direction: column;
}

.product-group-body-filter-rating .product-group-body-filter-rating-text {
  text-align: left;
  height: 40px;
}

.product-group-body {
  .product-group-body-list {
    flex: 4;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);

    gap: 10px; /* Optional: Adds space between grid items */
    box-sizing: border-box;
  }

  .product-group-no-products {
    flex: 4;

    margin: 250px 0 auto 0;
    text-align: center;
    font-size: 30px;
    align-items: center;

    span {
      padding-right: 100px;
    }
  }

  .product-group-body-grid-item {
    display: flex;
    color: black;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
  }

  .product-group-body-list img {
    filter: contrast(90%);
  }

  .product-group-body-grid-item-image-container {
    background-color: #f5f5f5;
    width: 200px;
    height: 200px;
  }

  .product-group-body-grid-item-image-container .AsyncImage-root {
    position: relative;
    top: 10px;
    left: 10px;
  }

  .product-group-body-list .product-group-item-product-name {
    height: 30px;
    width: 200px;

    margin-top: 10px;
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    position: relative;
    bottom: 20px;
  }

  .product-group-item-price-and-rating {
    display: flex;
    flex-direction: row;
    width: 200px;

    font-size: 13px;

    position: relative;
    bottom: 20px;
  }

  .product-group-item-price-and-rating .product-group-item-product-price {
    width: 50px;
    text-align: left;
  }

  .product-group-item-price-and-rating .product-group-item-list-price {
    font-weight: 300;
    width: 50px;
    margin-left: 10px;
    margin-right: 5px;
    text-decoration: line-through;
    color: #969696;
  }

  .product-group-body-list .product-group-item-price-and-rating {
    width: 200px;
    margin-left: 5px;
  }

  .product-group-item-rating {
    display: flex;
    flex-direction: row;
    width: 120px;
  }

  .product-group-item-rating span {
    position: relative;
  }

  .product-group-item-price-and-rating .product-group-item-rating-count {
    margin-left: 10px;
    color: #969696;
    font-size: 13px;
  }

  .product-group-item-price-and-rating .product-group-item-rating-number {
    margin-left: 5px;
  }

  .product-group-item-status-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 200px;
    position: relative;
    bottom: 200px;
  }

  .product-group-item-discount {
    height: 30px;
    width: 80px;
    display: flex;
    background-color: #cfe320;
  }

  .product-group-item-discount span {
    margin: auto;
  }

  .product-group-item-status span {
    position: relative;
    top: 3px;
  }

  .product-group-item-limited {
    background-color: #0ca788;
    width: 80px;
  }

  .product-group-item-hot {
    background-color: #ff7f40;
    width: 60px;
  }

  .product-group-pagination {
    flex: 1;
    margin-top: 40px;
  }
}
