/* Style for the overlay */
.category-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100; /* Ensure it covers other content */
}

.category-overlay.show {
  transform: translateX(0); /* Slide overlay into view */
}

.category-overlay-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    height: 70px;
    display: flex;
    background-color: #cfe320;

    .header-left-col {
      flex-grow: 1; /* Takes the remaining space */
      display: flex;
      align-items: center; /* Center content vertically */
      justify-content: center; /* Center content horizontally */
    }

    .header-right-col {
      width: 70px;
      display: flex;
      justify-content: center; /* Center content horizontally */
      align-items: center; /* Center content vertically */
    }
  }

  .body {
    margin: 0 20px;

    .category-title {
      height: 80px;
      display: flex;
      align-items: center;
    }
    .categories-list-container {
      display: flex;
      flex-direction: column;

      .main-category {
        height: 60px;
        display: flex;
        align-items: center;

        .category-name {
          flex-grow: 1;
        }

        .icon {
          width: 50px;
        }
      }

      .sub-category-list {
        display: flex;
        flex-direction: column;

        .sub-category {
          display: flex;
          align-items: center;
          padding: 0 40px;
          height: 50px;
          text-decoration: none;
          color: black;
          .sub-category-name {
            flex-grow: 1;
          }

          .icon {
            width: 50px;
          }
        }
      }
    }
  }
}
