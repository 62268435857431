.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

.coupon-code-modal {
  position: absolute;
  top: 40%;
  left: 40%;
  right: auto;
  bottom: auto;
  width: 450px;
  height: 280px;

  background-color: #ffffff;
  border-radius: 10px;
}

.coupon-code-modal:focus {
  outline: 0;
}

.coupon-code-container {
  display: flex;
  flex-direction: column;

  .coupon-code-row {
    display: flex;
    height: 120px;
  }

  .coupon-code-col {
    display: flex;
    flex-grow: 0.6;
    flex-direction: column;
  }

  .coupon-code-title {
    background-color: #cfe320;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .coupon-code-emoji {
    width: 60px;
    position: relative;
    left: 30px;
    top: 25px;
  }

  .coupon-code-text-container {
    margin-left: 40px;
  }

  .coupon-code-text {
    font-size: 20px;
    margin-top: 30px;
  }

  .coupon-code-soon-text {
    font-size: 12px;
    font-weight: 300;
    position: relative;
    top: -5px;
  }

  .coupon-code-bottom {
    flex-direction: column;
    margin-top: 20px;
  }

  .coupon-code-copy-button {
    width: 100%;
  }

  .coupon-code-icon {
    color: white;
    width: 23px;
    margin: 0 10px;
    position: relative;
    top: 5px;
  }

  .coupon-code-link-copy-icon {
    color: white;
    width: 23px;
    margin: 0 10px;
    position: relative;
    top: 5px;
  }

  .coupon-code-link-container {
    width: 100%;
    max-width: 330px;
    position: relative;
    left: 20%;
    height: 37px;
    background-color: #ffcc00;
    border-radius: 5px;

    margin-bottom: 10px;
  }

  .coupon-code-link-container:hover {
    cursor: pointer;
  }

  .coupon-code-copy-container {
    width: 100%;
    max-width: 330px;
    position: relative;
    left: 20%;
    height: 37px;

    border-radius: 5px;

    margin-bottom: 20px;
  }

  .coupon-code-copy-text {
    font-size: 14px;
    text-align: center;
    width: 180px;
    border: 1px dotted #b4b4b4;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .coupon-code-copy-text span {
    position: relative;
    top: 8px;
  }

  .coupon-code-copy-button {
    background-color: #cecece;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .coupon-code-copy-button:hover {
    cursor: pointer;
  }

  .coupon-code-link-text {
    position: relative;
    top: 5px;
    left: 10%;
  }

  .coupon-code-close-button-container {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 10px;
    left: 90%;
    background-color: #eaeaea;
    border-radius: 24px;
  }

  .coupon-code-close-button-container:hover {
    cursor: pointer;
  }

  .coupon-code-close-button {
    position: relative;
    bottom: 2px;
  }
}
