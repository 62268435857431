.mobile-main-page-header {
  height: 160px;
  display: flex;
  flex-direction: column;
  background-color: #cfe320;

  .mobile-main-page-header-row1 {
    display: flex;
    flex-direction: row;
    height: 60px;
    padding: 15px 5px 0 5px;

    .mobile-main-page-header-left-column,
    .mobile-main-page-header-right-column {
      width: 80px;
      display: flex;

      .col {
        display: flex;
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
      }
    }

    .mobile-main-page-header-right-column {
      flex-direction: row;
    }

    .mobile-main-page-header-middle-column {
      flex-grow: 1;
      display: flex;
      align-items: center; /* Center content vertically */
      justify-content: center; /* Center content horizontally */
    }
  }

  .language-dropdown {
    position: unset;
    margin-left: auto;
    margin-right: 20px;

    ul {
      width: 150px;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .mobile-main-page-header-row2 {
    flex-grow: 1;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    display: flex;

    .secondary-navbar-search-bar {
      width: 200%;
      height: 40px;
      box-sizing: border-box;
      margin: 0 20px;
      padding-left: 20px;
      font-size: 13px;
    }

    .secondary-navbar-search-icon {
      position: absolute;
      right: 40px;
      left: unset;
      pointer-events: none;
    }
  }
}
