.tabs {
  width: 100%;
  margin: 20px 0;
}

.tab-buttons {
  display: flex;
  border-bottom: 0.5px solid #969696;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  font-weight: 200;
  background-color: #eaeaea;
  max-width: 300px;
  width: 100%;
  transition:
    color 0.3s,
    border-bottom 0.3s;
}

.tab-button.active {
  font-weight: 500;
  background-color: #dee8a9;
}

.tab-content {
  padding: 20px 0;
}

.tab-panel {
  text-align: left;
  font-size: 18px;
}

.tab-content .product-content {
  overflow-y: scroll;
  max-height: 650px;
}

.product-content {
  .product-review-container {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
  }

  .product-review-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .product-review-text {
    margin-left: 5px;
  }

  .product-review-date {
    margin-left: 10px;
    font-weight: 300;
  }

  .product-review-title {
    font-weight: 700;
  }

  .product-review-profile {
    color: #969696;
  }

  .product-review-link {
    margin-left: 15px;
    text-decoration: underline;
  }

  .product-review-link:hover {
    cursor: pointer;
  }
}

.tab-panel ul li {
  margin-bottom: 20px;
}

// TODO:
.tab-panel p {
  margin: 0 20px;
}

.tab-panel h2 {
  margin: 0 20px 10px 20px;
}
