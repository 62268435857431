.not-found-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 150px 100px 20px 100px;
  padding: 30px 0px 30px 0px;
  background-color: #fff;
  border-radius: 13px;
  height: 500px;
}

.no-found-image {
  width: 500px;
  height: 300px;
}

.no-found-message {
  width: 50%;
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}
