.footer-redesign-container-parent {
  background-color: #e9e8e8;

  position: relative;
  top: 300px;
  height: 300px;
}

.footer-redesign-container {
  display: flex;
  flex-direction: row;

  height: 300px;
  padding: 60px 20px 60px 20px;
  position: relative;
  margin: auto;

  max-width: 1344px;
}

.footer-col {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.footer-text {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.footer-title {
  font-size: 20px;
  font-weight: 600;
}

.footer-link {
  text-decoration: none;
  color: black;
}

.footer-email-container {
  border-bottom: 1px solid black;
  width: 400px;

  display: flex;
}

.footer-text input {
  border: unset;
  width: 350px;
  height: 30px;
  padding-bottom: 0;
  background-color: #e9e8e8;

  position: relative;
  top: 10px;

  font-weight: 300;
}

.footer-text input:focus {
  outline: none;
}

.footer-email-container svg:hover {
  cursor: pointer;
}
