.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure it covers other content */
}

.login-modal-overlay.show {
  transform: translateX(0);
}

.login-modal-overlay-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;

  .header {
    height: 6vh;
    display: flex;
    background-color: #cfe320;

    .header-left-col {
      flex-grow: 1; /* Takes the remaining space */
      display: flex;
      align-items: center; /* Center content vertically */
      justify-content: center; /* Center content horizontally */
    }

    .header-right-col {
      width: 15vw;
      display: flex;
      justify-content: center; /* Center content horizontally */
      align-items: center; /* Center content vertically */
    }
  }

  .body {
    margin: 0 20px;

    .login-modal-title {
      height: 100px;
      display: flex;
      align-items: center;
      font-weight: 500;
    }

    .signup-modal-title {
      margin-top: 30px;
      height: 20px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
    }

    .signup-modal-subtitle {
      display: flex;
      align-items: center;
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 30px;
    }

    .modal-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 30px;
      padding: 30px 15px;
      background: white;
      border-radius: 10px;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);

      .row {
        width: 100%;
        height: 50px;
      }

      .email-input-container,
      .password-input-container {
        height: 60px;
      }

      .input-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 400px;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 5px 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .input-field {
          flex: 1;
          border: none;
          outline: none;
          padding: 10px;
          font-size: 14px;
        }
      }

      .form-button {
        border: none;
        outline: none;
        border-radius: 5px;
      }

      .form-button.disabled {
        background: #efefef;
        color: black;
      }

      .form-button.enabled {
        background: black;
        color: white;
      }

      .error-message {
        position: relative;
        top: 5px;
      }

      .divider-row {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5px;

        .divider {
          position: relative;
          width: 100%;
          height: 1px;
          background-color: #ccc;
        }

        .divider-text {
          position: absolute;
          top: -10px; // Adjust vertical position
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
          padding: 0 10px;
          color: #555;
          font-size: 13px;
        }
      }

      .google-button-row {
        display: flex;
        justify-content: center;

        .google-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff; // Google button background
          border: 1px solid #ccc; // Border color
          padding: 10px 20px; // Adjust padding
          border-radius: 5px; // Rounded corners
          font-size: 14px; // Adjust font size
          font-weight: bold;
          color: #555; // Text color
          cursor: pointer;
          transition:
            background-color 0.3s ease,
            border-color 0.3s ease;
        }

        .google-button:hover {
          background-color: #f7f7f7; // Hover background
          border-color: #aaa; // Hover border color
        }

        .google-icon {
          margin-right: 10px; // Spacing between icon and text
          color: #4285f4; // Google blue color
        }
      }

      .signup-user-info {
        .signup-user-email {
          align-self: flex-start;
          text-align: left;
          width: 100%;
          font-size: 13px;
          font-weight: 200;
        }

        .signup-go-back {
          align-self: flex-start;
          text-align: left;
          width: 100%;
          font-size: 15px;
          text-decoration: underline;
        }

        .signup-go-back:hover {
          cursor: pointer;
        }
      }

      .confirm-password-input-field {
        margin-top: 40px;
        margin-bottom: 5px;
      }

      .helper-text {
        font-size: 12px;
        color: #666;
        margin-top: 5px;
      }
    }
  }
}
