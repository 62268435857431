.main-page-shop-by-category-container {
  display: flex;
  width: 1344px;
  height: 573px;

  padding-left: 0px !important;
  padding-right: 0px !important;
  gap: 33px;
}

.category-link {
  text-decoration: none;
  color: black;
}

.col-home-kitchen-kids-toys-fashion-sports {
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.col-home-kitchen-kids-toys-fashion-sports {
  width: 982px;
}

.col-tech-gadgets {
  width: 328px;
  height: 573px;
}

.row-home-kitchen-kids-toys,
.row-fashion-beauty-health-sports {
  height: 270px;

  display: flex;
  gap: 33px;
  flex-direction: row;
}

.col-home-kitchen-text-button {
  display: flex;
  flex-direction: column;

  height: 200px;
  width: 100px;
  position: relative;
  left: 420px;
  bottom: 230px;
}

.col-home-kitchen-text-button .col-home-kitchen-text {
  flex: 2;
  font-size: 20px;
  font-weight: 350;

  text-align: left;
  height: 40px;
}

.col-home-kitchen-button-container {
  height: 40px;
  width: 40px;
  background-color: #eaeaea;

  border-radius: 24px;
}

.home-kitchen-button,
.kids-and-toys-button,
.fashion-beauty-button,
.health-sports-button,
.tech-gadgets-button {
  position: relative;
  top: 6px;
}

.col-kids-and-toys-text-button {
  display: flex;
  flex-direction: column;
  width: 100px;
  position: relative;

  height: 250px;
  left: 300px;
  bottom: 250px;
}

.col-kids-and-toys-text {
  flex: 4;
  font-size: 20px;
  font-weight: 350;
  position: relative;
  top: 30px;
  text-align: left;
}

.col-kids-and-toys-button-container {
  height: 40px;
  width: 40px;
  border-radius: 24px;

  background-color: #eaeaea;
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 25px;
}

.col-fashion-beauty-text-button,
.col-health-sports-text-button {
  width: 100px;
  height: 220px;
  display: flex;
  flex-direction: column;

  position: relative;
  bottom: 240px;
  left: 30px;
}

.col-fashion-beauty-text,
.col-health-sports-text {
  flex: 3;

  font-size: 20px;
  font-weight: 350;
  text-align: left;
}

.col-fashion-beauty-button-container,
.col-health-sports-button-container {
  height: 40px;
  width: 40px;
  background-color: #eaeaea;
  border-radius: 24px;
}

.col-tech-gadgets-text-button {
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;

  position: relative;
  bottom: 120px;
  left: 40px;
}

.col-tech-gadgets-text {
  flex: 3;
  font-size: 20px;
  font-weight: 350;
  text-align: left;
}

.col-tech-gadgets-button-container {
  height: 40px;
  width: 40px;
  background-color: #eaeaea;
  border-radius: 24px;
}

.col-home-kitchen-image,
.col-health-sports-image {
  width: 544px;
  height: 270px;
}

.col-kids-and-toys-image,
.col-fashion-beauty-image {
  width: 405px;
  height: 270px;
}

.category-box:hover {
  cursor: pointer;
}
