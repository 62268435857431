.image-gallery {
  display: flex;
  align-items: center;

  .thumbnail-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .thumbnails {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
      max-height: 500px;
      margin-left: 10px;
    }

    .thumbnail {
      width: 80px;
      height: 80px;
      margin: 5px;
      cursor: pointer;
      border: 2px solid transparent;
      transition: border 0.3s;
    }

    .thumbnail.selected {
      border: 3px solid #007bff;
      border-radius: 5px;
    }

    .arrow {
      font-size: 24px;
      cursor: pointer;
      margin: 10px 0;
    }
  }

  .main-image-container {
    margin-left: 20px;
    position: relative;

    .main-image-len {
      width: 250px;
      height: 250px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
    }

    .main-image-result {
      position: absolute;
      left: calc(100% + 50px);
      top: 0px;
      width: 400px;
      height: 400px;

      z-index: 1;
      background-repeat: no-repeat;
    }

    .hide {
      display: none;
    }
  }

  .main-image {
    width: 600px;
    height: 600px;
    transition: transform 0.3s;
    opacity: 1;
  }

  .main-image.fade {
    opacity: 0.5;
  }

  .main-image:hover {
    cursor: pointer;
  }
}
