.contact-us-container {
  height: 600px;
  width: 40%;
  position: relative;
  left: 30%;
  margin: 150px 0 0px 0px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-title {
  font-size: 30px;
  font-weight: 700;
}

.contact-us-form-field {
  width: 80%;
  height: 50px;
  margin: 50px 0px 5px 0px;
  padding-left: 20px;
  border-radius: 10px;
  border: 1px solid #dcd9d9;
  &:hover,
  &:focus {
    cursor: text;
  }
}

.contact-us-form-phone-field {
  margin-top: 50px;
}

.desktop-form-textarea {
  width: 80%;
  padding: 10px 0px 0px 20px;
  border-radius: 10px;
  margin: 50px 0px 10px 0px !important;
  height: 150px;
  border: 1px solid #dcd9d9;

  &:hover,
  &:focus {
    cursor: text;
  }
}

.error-message {
  width: 80%;
  text-align: left;
  padding-left: 5px;
}

.contact-form-submit {
  width: 35%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #cfe320;
  border: unset;
}

.mobile-contact-us-container {
  height: 75vh;
  width: 100%;
  position: relative;
  left: 0%;
  top: 5vh;
  margin: 150px 0 0px 0px;
}

.mobile-contact-title {
  font-size: 25px;
  font-weight: 700;
}

.mobile-contact-content {
  font-size: 14px;
  width: 80%;
}

.mobile-contact-us-form-field {
  width: 80%;
  height: 5vh;
  margin: 10px 0px;
  padding-left: 15px;
  border-radius: 10px;
  font-size: 13px;
  border: 1px solid #dcd9d9;
  &:hover,
  &:focus {
    cursor: text;
  }
}

.mobile-form-textarea {
  width: 80%;
  padding: 10px 0px 0px 15px;
  border-radius: 10px;
  margin: 10px 0px;
  height: 15vh;
  font-size: 13px;
  border: 1px solid #dcd9d9;

  &:hover,
  &:focus {
    cursor: text;
  }
}

.mobile-error-message {
  width: 80%;
  font-size: 13px;
  text-align: left;
  padding-left: 5px;
}

.mobile-contact-form-submit {
  width: 80%;
  height: 5vh;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #9bf3a0;
  color: #1c1c1c;
}
