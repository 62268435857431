.navbar-container .product-group-category-banner-container {
  margin-left: auto;
  margin-right: auto;

  height: 285px;
  position: relative;
  top: 120px;
  background-color: #f0f0f0;
}

.product-group-category-banner-container
  .product-group-category-banner-background-image {
  background-image: url("../../images/category-banner-home-kitchen.png");
  margin-left: auto;
  margin-right: auto;
  height: 285px;
  max-width: 1344px;
}

.product-group-category-banner-container .product-group-category-banner {
  height: 120px;

  width: 350px;
  position: relative;
  top: 100px;
  right: 70px;

  text-align: left;
  float: right;
  display: flex;
  flex-direction: column;

  .product-group-category-banner-title {
    flex: 3;
    font-size: 32px;
    font-weight: 600;
  }

  .product-group-category-banner-subTitle {
    flex: 1;
    font-size: 20px;
  }
}
