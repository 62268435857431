.mobile-image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-image-container {
    position: relative;
    margin: 10px 0;

    .main-image-len {
      width: 250px;
      height: 250px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
    }

    .main-image-result {
      position: absolute;
      left: calc(100% + 50px);
      top: 0;
      width: 400px;
      height: 400px;
      z-index: 1;
      background-repeat: no-repeat;
    }

    .hide {
      display: none;
    }
  }

  .main-image {
    width: 400px;
    height: 400px;
    transition: transform 0.3s;
    opacity: 1;
  }

  .main-image.fade {
    opacity: 0.5;
  }

  .main-image:hover {
    cursor: pointer;
  }

  .thumbnail-container {
    display: flex;
    align-items: center;
    width: 110%;

    .thumbnails {
      display: flex;
      align-items: center;
      overflow-x: scroll;

      max-height: 500px;
      margin: 0 10px;
    }

    .thumbnail {
      width: 80px;
      height: 80px;
      margin: 5px;
      cursor: pointer;
      border: 2px solid transparent;
      transition: border 0.3s;
    }

    .thumbnail.selected {
      border: 3px solid #007bff;
      border-radius: 5px;
    }

    .arrow {
      font-size: 24px;
      cursor: pointer;
      margin: 10px 0;
    }
  }
}
