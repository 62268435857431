.mobile-product-page {
  width: 100%;
  overflow-x: hidden;

  .mobile-product-detail-page {
    margin: 20px;

    .mobile-product-item-category-rating-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .mobile-product-item-rating {
        width: 100px;
        display: flex;
        column-gap: 5px;
        justify-content: center;

        .product-group-item-rating-icon {
          display: flex;
          justify-content: center;
        }
      }

      .mobile-product-item-category {
        text-decoration: underline;
      }

      span {
        font-size: 14px;
      }
    }

    .mobile-product-item-status-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      .mobile-product-item-discount {
        background-color: #cfe320;
        width: 90px;
        font-size: 18px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
      }
    }

    .mobile-product-item-name {
      font-size: 14px;
    }

    .product-description-image-gallery {
      position: relative;

      .product-favorite-icon-container {
        position: absolute;
        top: 15px;
        right: 0;
        z-index: 10;
        cursor: pointer;
        background-color: white;
      }

      .favorite-icon-container svg {
        pointer-events: none;
      }
    }

    .mobile-product-item-price-container {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      margin: 20px 0;

      .mobile-product-item-price {
        color: #2a8703;
        font-size: 20px;
      }

      .mobile-product-item-list-price-container {
        display: flex;
        align-items: center;
        column-gap: 20px;

        .mobile-product-item-list-price {
          color: #969696;
          text-decoration: line-through;
        }
      }
    }

    .mobile-product-item-buttons {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;

      .mobile-product-item-product-button-container {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .mobile-product-item-product-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 10px;
      }

      .mobile-product-item-product-button-subcontent {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        bottom: 30px;
        border-radius: 10px;
        background-color: #eaeaea;
        z-index: -1;
        padding: 0 10px 5px 10px;
        height: 80px;
      }

      .mobile-product-item-product-code {
        background-color: #cfe320;
      }

      .mobile-product-item-product-link {
        background-color: #f0c401;
      }

      .mobile-product-item-expiring-date {
        font-size: 13px;
      }
    }

    .mobile-product-item-details {
      .mobile-product-item-details-title {
        width: 100%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }
      .mobile-product-item-details-content {
        font-size: 13px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
      }
      .mobile-product-item-details-content.visible {
        max-height: 1000px;
      }

      .mobile-product-item-details-content.hidden {
        max-height: 0;
      }
    }

    .mobile-product-item-reviews-container {
      .mobile-product-item-reviews-title {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }
      .mobile-product-item-reviews {
        font-size: 13px;
        max-height: 0;
        overflow: hidden;
        margin: 10px 0;
        transition: max-height 0.5s ease;

        .product-review-container {
          .product-review-rating {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 10px;
            margin-bottom: 10px;
          }
          .product-review-link {
            margin-top: 10px;
            text-decoration: underline;
          }
        }
      }
      .mobile-product-item-reviews.visible {
        max-height: 1000px;
      }

      .mobile-product-item-reviews.hidden {
        max-height: 0;
      }
    }

    .mobile-product-item-recommended-products-container {
      min-height: 200px;

      .mobile-product-item-recommended-products-title {
        margin-bottom: 20px;
      }

      .mobile-product-item-recommended-products-list {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        max-height: 500px;
        overflow-y: scroll;

        .mobile-product-item-recommended-product {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: #f5f5f5;
          text-decoration: unset;
          color: black;

          .mobile-product-item-recommended-product-image {
            width: 40%;
            height: 40%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .mobile-product-item-recommended-product-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-left: 20px;
            row-gap: 5px;

            .mobile-product-item-recommended-product-discount {
              background-color: #cfe320;
              width: 30%;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              font-size: 13px;
            }
            .mobile-product-item-recommended-product-name {
              font-size: 12px;
            }
            .mobile-product-item-recommended-product-price-container {
              display: flex;
              flex-direction: row;

              .mobile-product-item-recommended-product-price {
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 13px;
              }

              .mobile-product-item-recommended-product-listPrice {
                text-decoration: line-through;
                font-size: 13px;
                font-weight: 300;
                color: #969696;
                display: flex;
                align-items: center;
                width: 30%;
                position: relative;
                top: 1px;
                margin-left: 10px;
              }

              .mobile-product-item-recommended-product-rating {
                width: 60%;
                font-weight: 300;
                display: flex;
                flex-direction: row;
                column-gap: 5px;
                justify-content: center;
                align-items: center;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  .mobile-product-item-coupon-code-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: white;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(100%);
    transition: transform 0.3s ease;

    .mobile-product-item-coupon-code-header {
      height: 150px;
      background-color: #cfe320;
      display: flex;
      flex-direction: row;
      padding: 0 20px;

      .mobile-product-item-coupon-code-emoji {
        height: 100%;
        width: 150px;
        display: flex;
        align-items: center;
      }

      .mobile-product-item-coupon-code-title-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px 0;

        .mobile-product-item-coupon-code-title-row {
          height: 100px;
        }

        .close-button-container {
          background-color: #eaeaea;
          width: 24px;
          height: 24px;
          border-radius: 24px;
          display: flex;
          align-items: center;
          position: relative;
          left: 90%;
        }

        .coupon-code-text {
          font-size: 20px;
        }

        .coupon-code-expired-soon-text {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }

    .mobile-product-item-coupon-code-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin-top: 20px;

      .mobile-product-item-coupon-code-body-row {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        .mobile-product-item-coupon-code-copy-container {
          display: flex;
          flex-direction: row;
          width: 70%;
          height: 50px;
          align-items: center;

          .mobile-product-item-coupon-code-copy-text {
            width: 100%;
            height: 100%;
            display: flex;
            border: 1px dotted #b4b4b4;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          .mobile-product-item-coupon-code-copy-button {
            height: 100%;
            width: 130%;
            display: flex;
            align-items: center;
            background-color: #cecece;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            .coupon-code-icon {
              color: white;
              margin-left: 20px;
            }
            .coupon-code-link-text {
              margin-left: 10px;
            }
          }
        }

        .mobile-product-item-coupon-code-link-container {
          background-color: #ffcc00;
          height: 50px;
          width: 70%;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 20px;
        }
      }
    }
  }

  .mobile-product-item-coupon-code-container.active {
    transform: translateY(0);
  }
}
