.mobile-terms-of-service-page {
  display: flex;
  flex-direction: column;

  .mobile-terms-of-service-container {
    margin: 20px;

    ul {
      list-style-type: none;
    }

    h2 {
      display: flex;
      justify-content: center;
    }

    .tos-content,
    .tos-list-content {
      font-size: 14px;
    }
  }
}
