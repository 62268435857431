.terms-of-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  width: 1344px;
  margin: auto;

  position: relative;
  top: 150px;

  ul {
    list-style-type: none;
  }
}

.tos-content {
  text-align: left;
}

.tos-list {
  margin-top: 30px;
  h3 {
    margin-bottom: 20px;
  }

  div {
    text-align: left;
  }
}
