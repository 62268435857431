.main-page-container {
  display: flex;
  flex-direction: column;
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 150px;
  height: 2400px;
}

.main-page-container .row {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;

  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.main-page-container .main-page-banner {
  flex: 2;
}

.main-page-banner .col {
  height: 100%;
}

.main-page-banner .main-page-banner-shop-now-button {
  width: 250px;
  height: 55px;
  border-radius: 10px;
  background-color: #78d64a;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;

  position: relative;
  left: 140px;
  bottom: 100px;
}

.main-page-banner-shop-now-button:hover {
  cursor: pointer;
}

.main-page-banner-shop-now-button span {
  position: relative;
  top: 10px;
}

.main-page-container .row-2 {
  flex: 2;
}

.main-page-container .row-3 {
  flex: 0.4;
  padding: 30px 0 60px 0;
}

.main-page-container .row-3 .main-page-view-all-products {
  padding-top: 10px;
  height: 55px;
  width: 300px;
  background-color: #78d64a;
  border-radius: 10px;
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.main-page-container .row-3 .main-page-view-all-products:hover {
  cursor: pointer;
}

.main-page-container .row-4 {
  flex: 0.2;
}

.main-page-container .main-page-shop-by-category-title {
  height: 50px;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}

.main-page-container .row-5 {
  flex: 1;
}

.main-page-container .row-6 {
  padding-top: 100px;
  flex: 1;
}

.mobile-main-page-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .mobile-main-page-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .mobile-main-page-gallery {
      height: 100%;
      margin: 20px 0;

      .gallery-image {
        width: 100%;
      }
    }

    .main-page-banner-shop-now-button {
      width: 35%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: #78d64a;

      position: relative;
      left: 50%;
      bottom: 45px;
      color: white;

      .main-page-banner-shop-now-button-text {
        text-decoration: none;
        font-weight: 600;
      }
    }

    .mobile-main-page-view-all-products-button {
      margin: 0 auto;
      width: 50%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #78d64a;
      height: 40px;
      color: white;
    }

    .mobile-main-page-trending-products {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .trending-product-row-products-title {
        width: 100%;
        font-size: 18px;
        display: flex;
        justify-content: center;
      }
    }

    .mobile-main-page-trust-banner {
      margin-top: 20px;
      overflow: hidden;
      background-color: #78d64a;
      padding-left: 20px;

      .trust-image {
        width: 100%;
        transform: scale(1.1);
      }
    }

    .mobile-main-page-shop-by-categories {
      display: flex;
      flex-direction: column;

      .shop-by-category-title {
        margin: 10px 0;
        display: flex;
        justify-content: center;
      }

      .shop-by-category-container {
        display: flex;
        flex-direction: column;
        row-gap: 40px;

        .shop-by-category-col,
        .AsyncImage-root {
          height: 200px;
          text-decoration: none;
          color: black;
        }

        .shop-by-category-home-kitchen-text-container {
          position: relative;
          width: 120px;
          left: 75%;
          bottom: 150px;
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          .shop-by-category-home-kitchen-text {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-wrap: wrap;
          }
          .col-home-kitchen-button-container {
            display: flex;
            justify-content: center;
          }
        }

        .shop-by-category-kids-toys-text-container {
          position: relative;
          width: 120px;
          left: 72%;
          bottom: 150px;
          display: flex;
          flex-direction: column;

          .shop-by-category-kids-toys-text {
            height: 40px;
          }
          .col-kids-and-toys-button-container {
            display: flex;
            justify-content: center;
            bottom: unset;
          }
        }

        .shop-by-category-fashion-beauty-text-container,
        .shop-by-category-health-sports-text-container {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 120px;
          left: 5%;
          bottom: 150px;
          row-gap: 20px;

          .col-fashion-beauty-button-container,
          .col-health-sports-button-container {
            display: flex;
            justify-content: center;
          }
        }

        .shop-by-category-tech-gadgets-text-container {
          display: flex;
          width: 120px;
          position: relative;
          left: 15%;
          bottom: 80px;
          transform: rotate(30deg);
          transform-origin: center;

          .col-tech-gadgets-button-container {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .mobile-main-page-footer {
    min-height: 150px; /* Minimum height for the footer */
    border: 1px solid red;
  }
}
